import Splide from "@splidejs/splide"
import { qs, qsa } from "./utils"

export const initGalleries = () => {
  const galleries = qsa("[data-gallery]")

  for (const slider of galleries) {
    const gallery = qs("[data-gallery-slider]", slider)

    new Splide(gallery, {
      width: "100%",
      cover: true,
      heightRatio: 0.5625,
      lazyLoad: "nearby",
    }).mount()
  }
}
