/**
 * Debounce function to limit the rate at which a function is called.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The delay in milliseconds.
 * @returns {Function} - The debounced function.
 */
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

let lastInnerWidth = window.innerWidth

/**
 * Sets the CSS custom properties for expanded and collapsed states based on the details element's height.
 * @param {HTMLElement} details - The details element.
 */
const setHeights = (details) => {
  const initiallyOpen = details.open
  details.open = true
  details.style.setProperty("--expanded", "auto")
  details.style.setProperty("--expanded", `${details.offsetHeight}px`)
  details.open = false
  details.style.setProperty("--collapsed", `${details.offsetHeight}px`)
  details.open = initiallyOpen
}

/**
 * Iterates over all details elements and sets their heights.
 */
const setDetailsHeights = () => {
  const details = document.querySelectorAll("[data-details]")
  for (const detail of details) {
    setHeights(detail)
  }
}

// Create a debounced version of setDetailsHeights
const debouncedSetDetailsHeights = debounce(setDetailsHeights, 100)

/**
 * Initializes the details functionality by adding the necessary event listeners.
 */
export const initDetails = () => {
  window.addEventListener("load", debouncedSetDetailsHeights)
  window.addEventListener("resize", () => {
    // Because Safari mobile view height changes, which trigger resize
    if (Math.abs(window.innerWidth - lastInnerWidth) < 5) return
    lastInnerWidth = window.innerWidth
    debouncedSetDetailsHeights()
  })
  window.addEventListener("cmp-embed-loaded", debouncedSetDetailsHeights)
}
